import {
  CompositionV2DataQuery,
  PostingsDataQuery,
} from '@revelio/data-access';
import { LineChart, LineData, LineDatum } from '@revelio/replots';

import { LineMetricValues } from '../config-provider/utils';
import { getFormat, getNormalizedData } from './utils';

type LineProps = {
  data: CompositionV2DataQuery | PostingsDataQuery | undefined;
  metric: LineMetricValues;
};

export const Line = ({ data, metric }: LineProps) => {
  const lineChartData = (() => {
    if (!data) return [];

    return getNormalizedData({ data, metric }).map((d): LineData => {
      const { dimension, timeseries } = d;

      const lineValues: LineDatum[] = timeseries.map((timePoint) => ({
        date: timePoint.date,
        value: timePoint.value ?? null,
      }));

      return { label: dimension, values: lineValues };
    });
  })();

  const format = getFormat(metric);

  return <LineChart data={lineChartData} format={format} />;
};
