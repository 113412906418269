import {
  CompositionV2DataQueryVariables,
  CompositionV2Entity,
  CompositionV2Filters,
  Filters,
  PositionsEntityKind,
} from '@revelio/data-access';
import { Filter, SelectionCategories } from '@revelio/filtering';

import {
  PrimaryDimension,
  getCommonFilters,
  isCustomRoleFilter,
  isFilterList,
} from './common';

type FiltersKey = keyof Filters;

/** ======== Filter List ======== */
const filterIdsToFilterKeys: Partial<Record<SelectionCategories, FiltersKey>> =
  {
    [SelectionCategories.RICS_K10]: 'rics_k10',
    [SelectionCategories.RICS_K50]: 'rics_k50',
    [SelectionCategories.RICS_K400]: 'rics_k400',
    [SelectionCategories.INDUSTRY]: 'industry',
    [SelectionCategories.COMPANY]: 'company',

    [SelectionCategories.REGION]: 'region',
    [SelectionCategories.COUNTRY]: 'country',
    [SelectionCategories.MSA]: 'msa',
    [SelectionCategories.METRO_AREA]: 'msa',

    [SelectionCategories.JOB_CATEGORY]: 'job_category',
    [SelectionCategories.ROLE_K150]: 'role_k150',
    [SelectionCategories.ROLE_K1500]: 'role_k1500',

    [SelectionCategories.SKILL_K75]: 'skill_k75',
    [SelectionCategories.SKILL_K700]: 'skill_k700',
    [SelectionCategories.SKILL_K3000]: 'skill_k3000',

    [SelectionCategories.HIGHEST_DEGREE]: 'highest_degree',
    [SelectionCategories.SENIORITY]: 'seniority',
    [SelectionCategories.ETHNICITY]: 'ethnicity',
    [SelectionCategories.GENDER]: 'gender',
    [SelectionCategories.KEYWORD]: 'keywords',
    [SelectionCategories.RAW_TITLE]: 'title_raw',
  };

const isFilterListId = (filterId: string): filterId is FiltersKey =>
  filterIdsToFilterKeys[filterId as SelectionCategories] !== undefined;

/** ================================
 * Get Composition V2 Filters
 ================================ */
export const getCompositionV2Filters = ({
  primaryDimension,
  filters,
}: {
  primaryDimension: 'company' | 'geography' | 'role';
  filters: Filter[];
}): CompositionV2DataQueryVariables => {
  const compositionFilters: CompositionV2Filters =
    filters.reduce<CompositionV2Filters>(
      (acc, filter): CompositionV2Filters => {
        if (isFilterPrimaryEntity({ primaryDimension, filter })) {
          return acc;
        }

        const commonAcc = getCommonFilters(acc, filter);

        if (isFilterListId(filter.id) && isFilterList(filter)) {
          const mappedKey =
            filterIdsToFilterKeys[filter.id as SelectionCategories];
          if (!mappedKey) return acc;
          return {
            ...commonAcc,
            [filter.id]: filter.value.map((item) => Number(item.id)),
          };
        }

        return acc;
      },
      { raw: false }
    );

  return {
    entities: getPrimaryEntities({ filters, primaryDimension }),
    filters: compositionFilters,
  };
};

const getEntityIds = (filter: Filter | undefined): number[] => {
  if (!filter || !isFilterList(filter)) return [];

  return filter.value.map((item) => Number(item.id));
};

/** ================================
 * Get Primary Entities
 ================================ */
const getPrimaryEntities = ({
  filters,
  primaryDimension,
}: {
  filters: Filter[];
  primaryDimension: PrimaryDimension;
}): CompositionV2Entity[] => {
  if (primaryDimension === 'company') {
    const companyEntities = filters.find(
      (filter) => filter.id === SelectionCategories.COMPANY
    );
    const ricsK10Entities = filters.find(
      (filter) => filter.id === SelectionCategories.RICS_K10
    );
    const ricsK50Entities = filters.find(
      (filter) => filter.id === SelectionCategories.RICS_K50
    );
    const ricsK400Entities = filters.find(
      (filter) => filter.id === SelectionCategories.RICS_K400
    );

    const companyIds = getEntityIds(companyEntities);
    const ricsK10Ids = getEntityIds(ricsK10Entities);
    const ricsK50Ids = getEntityIds(ricsK50Entities);
    const ricsK400Ids = getEntityIds(ricsK400Entities);

    return [
      ...companyIds.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.Company,
      })),
      ...ricsK10Ids.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.RicsK10,
      })),
      ...ricsK50Ids.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.RicsK50,
      })),
      ...ricsK400Ids.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.RicsK400,
      })),
    ];
  }

  if (primaryDimension === 'geography') {
    const regionEntities = filters.find(
      (filter) => filter.id === SelectionCategories.REGION
    );
    const countryEntities = filters.find(
      (filter) => filter.id === SelectionCategories.COUNTRY
    );
    const metroAreaEntities = filters.find(
      (filter) => filter.id === SelectionCategories.METRO_AREA
    );
    const msaEntities = filters.find(
      (filter) => filter.id === SelectionCategories.MSA
    );

    const regionIds = getEntityIds(regionEntities);
    const countryIds = getEntityIds(countryEntities);
    const metroAreaIds = getEntityIds(metroAreaEntities);
    const msaIds = getEntityIds(msaEntities);

    return [
      ...regionIds.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.Region,
      })),
      ...countryIds.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.Country,
      })),
      ...metroAreaIds.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.Msa,
      })),
      ...msaIds.map((id) => ({
        EntID: id,
        EntType: PositionsEntityKind.Msa,
      })),
    ];
  }

  if (primaryDimension === 'role') {
    const customRoleFilter = filters.find(
      (filter) => filter.id === SelectionCategories.CUSTOM_ROLE
    );

    if (customRoleFilter && isCustomRoleFilter(customRoleFilter)) {
      return customRoleFilter.value.entities.map((entity) => {
        const entType = (() => {
          switch (entity.levelId) {
            case 'role_k7':
              return PositionsEntityKind.RoleK7;
            case 'role_k150':
              return PositionsEntityKind.RoleK150;
            case 'role_k1500':
              return PositionsEntityKind.RoleK1500;
            default:
              return PositionsEntityKind.RoleK1500;
          }
        })();
        return {
          EntID: Number(entity.id),
          EntType: entType,
        };
      });
    }
  }
  return [];
};

/** ================================
 * Is Filter Primary Entity
 ================================ */
const isFilterPrimaryEntity = ({
  primaryDimension,
  filter,
}: {
  primaryDimension: PrimaryDimension;
  filter: Filter;
}): boolean => {
  if (
    primaryDimension === 'company' &&
    (filter.id === SelectionCategories.COMPANY ||
      filter.id === SelectionCategories.INDUSTRY ||
      filter.id === SelectionCategories.RICS_K10 ||
      filter.id === SelectionCategories.RICS_K50 ||
      filter.id === SelectionCategories.RICS_K400)
  ) {
    return true;
  }
  if (
    primaryDimension === 'geography' &&
    (filter.id === SelectionCategories.REGION ||
      filter.id === SelectionCategories.COUNTRY ||
      filter.id === SelectionCategories.METRO_AREA ||
      filter.id === SelectionCategories.MSA)
  ) {
    return true;
  }
  if (
    primaryDimension === 'role' &&
    (filter.id === SelectionCategories.CUSTOM_ROLE ||
      filter.id === SelectionCategories.JOB_CATEGORY ||
      filter.id === SelectionCategories.ROLE_K7 ||
      filter.id === SelectionCategories.ROLE_K150 ||
      filter.id === SelectionCategories.ROLE_K1500)
  ) {
    return true;
  }
  return false;
};
