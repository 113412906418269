import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { FaChartLine } from 'react-icons/fa';

import { Loading } from '@revelio/core';

import {
  ChartType,
  MetricValues,
  PrimaryDimensionValues,
  SecondaryDimensionValues,
  useConfig,
} from '../config-provider';
import {
  isBarMetric,
  isDistributionMetric,
  isLineMetric,
  isSankeyMetric,
  isStackedBarMetric,
  isVolumeMetric,
} from '../config-provider/utils';
import { Bar } from './bar';
import { ChartConfig } from './chart-config';
import styles from './chart.module.css';
import { Distribution } from './distribution';
import { Line } from './line';
import { Sankey } from './sankey';
import { StackedBar } from './stacked-bar';
import { useChartQuery } from './use-chart-query';
import { usePayData } from './use-pay-data';
import { Volume } from './volume';

type ChartProps = {
  id: string;
  title?: string;
  isDragging?: boolean;
};

export const Chart = ({ id, title, isDragging }: ChartProps) => {
  const { config } = useConfig();

  const item = config.items.find((item) => item.id === id);
  const metric = item?.plot?.metric;
  const primaryDimension = item?.plot?.primaryDimension;
  const secondaryDimension = item?.plot?.secondaryDimension;
  const chartType = item?.plot?.chartType;
  const {
    isOpen: isConfigMenuOpen,
    onOpen: openConfigMenu,
    onClose: closeConfigMenu,
  } = useDisclosure();

  const label = (() => {
    if (!metric) return 'Chart';

    return `${metric} by ${primaryDimension}`;
  })();

  return (
    <Card width="100%" height="100%" variant="unstyled">
      <CardHeader
        padding=".375rem .75rem .375rem"
        fontSize="12px"
        fontWeight="600"
        lineHeight="16px"
        className={styles.cardHeader}
        onClick={openConfigMenu}
        data-swapy-handle
      >
        <div className={styles.chartTitle}>{label}</div>
      </CardHeader>
      <CardBody padding=".5rem 1rem 1.25rem" height="calc(100% - 24px)">
        {primaryDimension && metric && chartType ? (
          <ChartSelector
            primaryDimension={primaryDimension}
            metric={metric}
            chartType={chartType}
            secondaryDimension={secondaryDimension}
            isDragging={isDragging}
          />
        ) : (
          <div className={styles.addChartContainer}>
            <Button
              size="lg"
              variant="ghost"
              onClick={openConfigMenu}
              display="flex"
              flexDirection="column"
              height="100px"
              paddingY="1rem"
            >
              <Icon boxSize="32px" as={FaChartLine} />
              Add Metric
            </Button>
          </div>
        )}
        <ChartConfig
          id={id}
          isOpen={isConfigMenuOpen}
          onClose={closeConfigMenu}
        />
      </CardBody>
    </Card>
  );
};

type ChartSelectorProps = {
  primaryDimension: PrimaryDimensionValues;
  metric: MetricValues;
  chartType: ChartType;
  secondaryDimension?: SecondaryDimensionValues;
  isDragging?: boolean;
};
const ChartSelector = ({
  primaryDimension,
  metric,
  chartType,
  secondaryDimension,
  isDragging,
}: ChartSelectorProps) => {
  const { data, fetching } = useChartQuery({ primaryDimension, metric });

  const { data: kdeData } = usePayData({
    primaryDimension,
    metric,
  });

  return (
    <>
      {chartType === 'bar' && isBarMetric(metric) && (
        <Bar data={data} metric={metric} />
      )}
      {chartType === 'line' && isLineMetric(metric) && (
        <Line data={data} metric={metric} />
      )}
      {chartType === 'sankey' && isSankeyMetric(metric) && (
        <Sankey data={data} metric={metric} />
      )}
      {chartType === 'volume' && isVolumeMetric(metric) && (
        <Volume data={data} metric={metric} />
      )}
      {chartType === 'distribution' && isDistributionMetric(metric) && (
        <Distribution data={kdeData} />
      )}
      {chartType === 'stacked-bar' && isStackedBarMetric(metric) && (
        <StackedBar
          data={data}
          secondaryDimension={secondaryDimension}
          stopResizeMonitoring={isDragging}
        />
      )}
      {fetching && (
        <div className={styles.loadingContainer}>
          <Loading size="sm" transparentBackground />
        </div>
      )}
    </>
  );
};
