import { UseQueryArgs, useQuery } from 'urql';

import { Views } from '@revelio/core';
import {
  CompositionV2DataQueryVariables,
  Dimension1,
  GetSentimentDataQueryVariables,
  PostingsDataQueryVariables,
  TransitionDataQueryVariables,
  TransitionDimension,
} from '@revelio/data-access';
import {
  COMPOSITION_GET_DATA_V2,
  POSTING_GET_DATA,
  SENTIMENT_GET_OVERTIME_DATA_V2,
  TRANSITIONS_DATA,
  getStartDateConst,
  useAllFiltersState,
} from '@revelio/filtering';

import {
  getCompositionV2Filters,
  getPostingFilters,
  getSentimentFilters,
  getTransitionFilters,
  hasPrimaryFilter,
  validatedPostingDates,
} from '../../../../filtering';
import { useMonthApiFilters } from '../../../entity-summary/use-month-api-filters';
import { getTimeLimitedStartDate } from '../../../sentiment/sentimentV2/hooks/use-get-sentiment-reviews-data';
import {
  MetricValues,
  PrimaryDimensionValues,
} from '../../components/config-provider';
import { DataQuery } from './types';

type QueryArgs =
  | UseQueryArgs<CompositionV2DataQueryVariables>
  | UseQueryArgs<TransitionDataQueryVariables>
  | UseQueryArgs<PostingsDataQueryVariables>
  | UseQueryArgs<GetSentimentDataQueryVariables>;

export const useChartQuery = ({
  primaryDimension,
  metric,
}: {
  primaryDimension: PrimaryDimensionValues;
  metric: MetricValues;
}) => {
  const filters = useAllFiltersState();
  const monthApiFilters = useMonthApiFilters();

  const dim1 = ((): Dimension1 => {
    switch (primaryDimension) {
      case 'company':
        return Dimension1.Company;
      case 'role':
        return Dimension1.Role;
      case 'geography':
        return Dimension1.Geography;
    }
  })();

  const queryFilters = ((): QueryArgs => {
    switch (metric) {
      case 'headcount':
      case 'attrition_rate':
      case 'growth_rate':
      case 'hiring_rate':
      case 'salary':
      case 'tenure': {
        const variables = getCompositionV2Filters({
          primaryDimension,
          filters,
        });

        return {
          query: COMPOSITION_GET_DATA_V2,
          variables,
          pause:
            !variables.entities ||
            (Array.isArray(variables.entities) &&
              variables.entities.length === 0),
        };
      }

      case 'transitions': {
        const transitionFilters = getTransitionFilters(filters);

        const isPrimaryFilterSet = hasPrimaryFilter({
          primaryDimension,
          filters,
        });
        return {
          query: TRANSITIONS_DATA,
          variables: {
            dim1,
            dim2: TransitionDimension.All,
            filters: {
              ...transitionFilters,
              node_limit: 10,
            },
          },
          pause: !isPrimaryFilterSet,
        };
      }

      case 'posting_salary':
      case 'time_to_fill':
      case 'postings_active':
      case 'postings_new':
      case 'postings_removed':
      case 'postings_volume': {
        const postingFilters = getPostingFilters(filters);
        const validatedPostingFilters = validatedPostingDates({
          filters: postingFilters,
          startDate:
            monthApiFilters?.date_range_full_api_filters?.start_date ??
            '2022-08-15',
          endDate:
            monthApiFilters?.date_range_full_api_filters?.end_date ??
            new Date().toISOString().split('T')[0],
        });

        const isPrimaryFilterSet = hasPrimaryFilter({
          primaryDimension,
          filters,
        });

        return {
          query: POSTING_GET_DATA,
          variables: {
            dim1,
            filters: {
              ...validatedPostingFilters,
              provider: [2],
              metric_mode: 'posting',
            },
          },
          pause: !isPrimaryFilterSet,
        };
      }

      case 'benefits':
      case 'culture':
      case 'compensation':
      case 'work_life_balance':
      case 'diversity_and_inclusion':
      case 'leadership':
      case 'business_outlook':
      case 'career_growth':
      case 'overall': {
        const sentimentFilters = getSentimentFilters(filters);

        const dateRangeFilter = sentimentFilters?.start_date;

        const timeLimitedStartDate =
          dateRangeFilter &&
          getTimeLimitedStartDate(
            dateRangeFilter,
            getStartDateConst(Views.SENTIMENT)
          );

        const isPrimaryFilterSet = hasPrimaryFilter({
          primaryDimension,
          filters,
        });

        return {
          query: SENTIMENT_GET_OVERTIME_DATA_V2,
          variables: {
            dim1,
            filters: {
              ...sentimentFilters,
              start_date: timeLimitedStartDate,
            },
          },
          pause: !isPrimaryFilterSet,
        };
      }

      case 'pay_distribution': {
        return { query: POSTING_GET_DATA, pause: true };
      }
    }
  })();

  const [{ data, fetching }] = useQuery<DataQuery>(queryFilters);

  return { data, fetching };
};
