import { TransitionDataQuery } from '@revelio/data-access';
import { SankeyChart, SankeyData } from '@revelio/replots';

import { transformSankey } from '../../../transitions/data-fetch/transform-sankey';
import { SankeyMetricValues } from '../config-provider/utils';

type SankeyProps = {
  data: TransitionDataQuery | undefined;
  direction?: 'inflow' | 'outflow';
  metric: SankeyMetricValues;
};

export const Sankey = ({
  data,
  direction = 'outflow',
  metric,
}: SankeyProps) => {
  const sankeyData = ((): SankeyData => {
    if (
      metric !== 'transitions' ||
      !data ||
      !('transitions2D' in data) ||
      !Array.isArray(data.transitions2D)
    ) {
      return { nodes: [], links: [] };
    }

    const [inflowData, outflowData] = data.transitions2D;
    const dataToUse = direction === 'inflow' ? inflowData : outflowData;

    if (!dataToUse) return { nodes: [], links: [] };

    return transformSankey(dataToUse, direction);
  })();

  return <SankeyChart data={sankeyData} isOutflows />;
};
