import { PostingsDataQuery } from '@revelio/data-access';
import { VolumeChart } from '@revelio/replots';

import { getMainPostingsData } from '../../../postings/utils';
import { VolumeMetricValues } from '../config-provider/types/metric';

export const Volume = ({
  data,
  metric,
}: {
  data: PostingsDataQuery | undefined;
  metric: VolumeMetricValues;
}) => {
  const volumeData = (() => {
    if (!data) return [];

    return getMainPostingsData({ entities: data.posting });
  })();

  return <VolumeChart data={volumeData} />;
};
