import { KdeChart } from '@revelio/replots';

import { getKdeData } from '../../../entity-summary/company-summary/salary-distribution/get-kde-data';
import { KdeSalaryData } from '../../../entity-summary/company-summary/salary-distribution/types';

export const Distribution = ({ data }: { data: KdeSalaryData | undefined }) => {
  const kdeData = data ? getKdeData(data, '') : [];

  return <KdeChart data={kdeData} />;
};
