import { DeleteIcon } from '@chakra-ui/icons';
import { Divider, Grid, Text } from '@chakra-ui/layout';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
} from '@chakra-ui/react';
import { BiLineChart } from 'react-icons/bi';
import { FaChartArea, FaRegChartBar } from 'react-icons/fa';
import { FcComboChart } from 'react-icons/fc';
import { MdOutlineStackedBarChart } from 'react-icons/md';
import { SingleValue } from 'react-select';

import { StyledPlotSelect } from '../../../../shared/components/plots/styled-plot-select';
import { SankeyIcon } from '../../assets/svg';
import {
  ChartType,
  MetricOption,
  PrimaryDimensionOption,
  SecondaryDimensionOption,
  metricOptions,
  primaryDimensionOptions,
  secondaryDimensionOptions,
  useConfig,
} from '../config-provider';
import styles from './chart-config.module.css';

type ChartConfigProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ChartConfig = ({ id, isOpen, onClose }: ChartConfigProps) => {
  const { config, dispatch } = useConfig();

  const item = config.items.find((item) => item.id === id);
  const selectedChartType = item?.plot?.chartType;
  const selectedMetricValue = item?.plot?.metric;
  const selectedPrimaryDimensionValue = item?.plot?.primaryDimension;
  const selectedSecondaryDimensionValue = item?.plot?.secondaryDimension;

  const selectedMetric = metricOptions.find(
    (metric) => metric.value === selectedMetricValue
  );
  const selectedPrimaryDimension = primaryDimensionOptions.find(
    (dimension) => dimension.value === selectedPrimaryDimensionValue
  );
  const selectedSecondaryDimension = secondaryDimensionOptions.find(
    (dimension) => dimension.value === selectedSecondaryDimensionValue
  );

  const onChangePrimaryDimension = (
    primaryDimension: SingleValue<PrimaryDimensionOption>
  ) => {
    if (!primaryDimension) return;
    dispatch({
      type: 'UPDATE_ITEM_PRIMARY_DIMENSION',
      payload: { id, primaryDimension: primaryDimension.value },
    });
  };

  const onChangeSecondaryDimension = (
    secondaryDimension: SingleValue<SecondaryDimensionOption>
  ) => {
    if (!secondaryDimension) return;
    dispatch({
      type: 'UPDATE_ITEM_SECONDARY_DIMENSION',
      payload: { id, secondaryDimension: secondaryDimension.value },
    });
  };

  const onChangeMetric = (metric: SingleValue<MetricOption>) => {
    if (!metric) return;
    dispatch({
      type: 'UPDATE_ITEM_METRIC',
      payload: { id, metric: metric.value },
    });
  };

  const onChangeChartType = (chartType: SingleValue<ChartType>) => {
    if (!chartType) return;
    dispatch({ type: 'UPDATE_ITEM_CHART_TYPE', payload: { id, chartType } });
  };

  const handleDeleteChart = () => {
    dispatch({ type: 'DELETE_ITEM', payload: { id } });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay display={'none'} />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Configure Chart
          <Divider />
        </DrawerHeader>
        <DrawerBody>
          <div className={styles.selector}>
            <Text fontSize="sm" fontWeight={600} marginBottom="0.5rem">
              Chart Type
            </Text>
            <Grid gridTemplateColumns="repeat(3, 1fr)" gap="1rem">
              <IconButton
                icon={<FaRegChartBar size="32" />}
                height="80px"
                colorScheme="blue"
                variant={selectedChartType === 'bar' ? 'solid' : 'outline'}
                aria-label="Chart Type"
                onClick={() => onChangeChartType('bar')}
              />
              <IconButton
                icon={<MdOutlineStackedBarChart size="32" />}
                height="80px"
                colorScheme="blue"
                variant={
                  selectedChartType === 'stacked-bar' ? 'solid' : 'outline'
                }
                aria-label="Chart Type"
                onClick={() => onChangeChartType('stacked-bar')}
              />
              <IconButton
                icon={<BiLineChart size="32" />}
                height="80px"
                colorScheme="blue"
                variant={selectedChartType === 'line' ? 'solid' : 'outline'}
                aria-label="Chart Type"
                onClick={() => onChangeChartType('line')}
              />
              <IconButton
                icon={<FcComboChart size="32" />}
                height="80px"
                colorScheme="blue"
                variant={selectedChartType === 'volume' ? 'solid' : 'outline'}
                aria-label="Chart Type"
                onClick={() => onChangeChartType('volume')}
              />
              <IconButton
                icon={<SankeyIcon width="32px" height="32px" />}
                height="80px"
                colorScheme="blue"
                variant={selectedChartType === 'sankey' ? 'solid' : 'outline'}
                aria-label="Chart Type"
                onClick={() => onChangeChartType('sankey')}
              />
              <IconButton
                icon={<FaChartArea size="32" />}
                height="80px"
                colorScheme="blue"
                variant={
                  selectedChartType === 'distribution' ? 'solid' : 'outline'
                }
                aria-label="Chart Type"
                onClick={() => onChangeChartType('distribution')}
              />
            </Grid>
          </div>
          <div className={styles.selector}>
            <Text fontSize="sm" fontWeight={600} marginBottom="0.5rem">
              Primary Dimension
            </Text>
            <StyledPlotSelect
              options={primaryDimensionOptions}
              value={selectedPrimaryDimension}
              onChange={onChangePrimaryDimension}
              isSearchable={true}
            />
          </div>
          <div className={styles.selector}>
            <Text fontSize="sm" fontWeight={600} marginBottom="0.5rem">
              Secondary Dimension
            </Text>
            <StyledPlotSelect
              options={secondaryDimensionOptions}
              value={selectedSecondaryDimension}
              onChange={onChangeSecondaryDimension}
              isSearchable={true}
            />
          </div>
          <div className={styles.selector}>
            <Text fontSize="sm" fontWeight={600} marginBottom="0.5rem">
              Metric
            </Text>
            <StyledPlotSelect
              options={metricOptions}
              value={selectedMetric}
              onChange={onChangeMetric}
              isSearchable={true}
            />
          </div>
          <Divider marginBottom={'1rem'} />
          {/* <div className={styles.selector}>
            <div className={styles.selectorHeader}>
              <Text fontSize="sm" fontWeight={600}>
                Company & Industry
              </Text>
              <Button
                size="xs"
                colorScheme="red"
                variant="link"
                onClick={clearCompanyIndustry}
              >
                Clear All
              </Button>
            </div>
            <div className={styles.tagContainer}>
              {companyIndustrySelections.map((selection) => (
                <Tag
                  key={selection.id}
                  size="sm"
                  variant="outline"
                  boxShadow="#46BEF1 0px 0px 0px 1px"
                >
                  <TagLabel>{selection.label}</TagLabel>
                  <TagCloseButton
                    onClick={() => handleRemoveCompanyIndustry(selection)}
                  />
                </Tag>
              ))}
            </div>
            <Popover
              isOpen={isCompanyIndustryOpen}
              onOpen={onCompanyIndustryOpen}
              onClose={onCompanyIndustryClose}
            >
              <PopoverTrigger>
                <Button
                  size="xs"
                  colorScheme="blue"
                  variant="link"
                  onClick={onCompanyIndustryOpen}
                >
                  + Add
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoutTreeFilter
                  selectionListIds={[
                    SelectionCategories.RICS_K10,
                    SelectionCategories.RICS_K50,
                    SelectionCategories.COMPANY,
                  ]}
                  maxSelections={6}
                  activeFilters={selectedIndustryCompany || []}
                  onSubmit={handleSubmitIndustryCompany}
                  onClose={onCompanyIndustryClose}
                />
              </PopoverContent>
            </Popover>
          </div> */}
          {/* <div className={styles.selector}>
            <div className={styles.selectorHeader}>
              <Text fontSize="sm" fontWeight={600}>
                Role
              </Text>
              <Button
                size="xs"
                colorScheme="red"
                variant="link"
                onClick={clearRole}
              >
                Clear All
              </Button>
            </div>
            <div className={styles.tagContainer}>
              {roleSelections.map((selection) => (
                <Tag
                  key={selection.id}
                  size="sm"
                  variant="outline"
                  boxShadow="#46BEF1 0px 0px 0px 1px"
                >
                  <TagLabel>{selection.label}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveRole(selection)} />
                </Tag>
              ))}
            </div>
            <Popover
              isOpen={isRoleOpen}
              onOpen={onRoleOpen}
              onClose={onRoleClose}
            >
              <PopoverTrigger>
                <Button
                  size="xs"
                  colorScheme="blue"
                  variant="link"
                  onClick={onRoleOpen}
                >
                  + Add
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoutTreeFilter
                  selectionListIds={[
                    SelectionCategories.JOB_CATEGORY,
                    SelectionCategories.ROLE_K150,
                    SelectionCategories.ROLE_K1500,
                  ]}
                  maxSelections={6}
                  activeFilters={selectedRole || []}
                  onSubmit={handleSubmitRole}
                  onClose={onRoleClose}
                />
              </PopoverContent>
            </Popover>
          </div> */}
          {/* <div className={styles.selector}>
            <div className={styles.selectorHeader}>
              <Text fontSize="sm" fontWeight={600}>
                Chart Date
              </Text>
              <Button
                size="xs"
                colorScheme="red"
                variant="link"
                onClick={clearDateRange}
              >
                Clear All
              </Button>
            </div>

            <Popover
              isOpen={isDateRangeOpen}
              onOpen={onDateRangeOpen}
              onClose={onDateRangeClose}
            >
              <PopoverTrigger>
                <Button
                  size="xs"
                  colorScheme="blue"
                  variant="link"
                  onClick={onDateRangeOpen}
                >
                  {dateRangeValue ? (
                    <Tag
                      size="sm"
                      variant="outline"
                      boxShadow="#46BEF1 0px 0px 0px 1px"
                    >
                      <TagLabel>
                        {dateRangeValue?.startDate &&
                          format(dateRangeValue.startDate, 'yyyy-MM')}{' '}
                        -{' '}
                        {dateRangeValue?.endDate &&
                          format(dateRangeValue.endDate, 'yyyy-MM')}
                      </TagLabel>
                      <TagCloseButton onClick={clearDateRange} />
                    </Tag>
                  ) : (
                    '+ Add'
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <CustomFilterRange
                    dateRangeValue={tempDateRangeValue}
                    setDateRangeValue={handleSetTempDateRangeValue}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    maxStartDate={new Date('2020-01-01')}
                    maxEndDate={new Date('2024-11-30')}
                    error=""
                    setError={handleSetDateRangeError}
                  />
                  <SelectionListControls
                    onClose={onDateRangeClose}
                    onSubmit={handleSubmitDateRange}
                    submitText="Update"
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div> */}
          <Divider />
          <Button
            size="sm"
            colorScheme="red"
            onClick={handleDeleteChart}
            width="100%"
            variant="outline"
            marginTop="1rem"
            leftIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
