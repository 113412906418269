import { CompositionV2DataQuery } from '@revelio/data-access';

import { CompositionMetricValues } from '../../../components/config-provider';
import { NormalizedData, NormalizedDataPoint } from './get-normalized-data';

type GetCompositionNormalizedDataProps = {
  data: CompositionV2DataQuery;
  metric: CompositionMetricValues;
};
export const getCompositionNormalizedData = ({
  data,
  metric,
}: GetCompositionNormalizedDataProps): NormalizedData[] => {
  if (!data.composition_v2) return [];

  const dateRefs = data.composition_v2.refs.months;
  return data.composition_v2.entities.map((entity): NormalizedData => {
    const metadata = entity.metadata;
    const metrics = entity.metrics;

    const dimension = metadata.short_name;
    const metricData = metrics[metric];

    const timeseries = metricData.map(
      (timeSeriesPoint): NormalizedDataPoint => ({
        date: dateRefs[timeSeriesPoint.id],
        value: timeSeriesPoint.value ?? null,
      })
    );

    return { dimension, timeseries };
  });
};
