import { useMemo } from 'react';

import { CompositionV2DataQuery, PostingsDataQuery   } from '@revelio/data-access';
import { StackedBarChartHorizontal } from '@revelio/replots';

import { SecondaryDimensionValues } from '../config-provider';
import { getStackedBarData } from './utils';

type StackedBarProps = {
  data: CompositionV2DataQuery | PostingsDataQuery | undefined;
  secondaryDimension: SecondaryDimensionValues | undefined;
  stopResizeMonitoring?: boolean;
};

const isCompositionV2Data = (
  data: CompositionV2DataQuery | PostingsDataQuery | undefined
): data is CompositionV2DataQuery => {
  return data !== undefined && 'composition_v2' in data;
};

export const StackedBar = ({
  data,
  secondaryDimension,
  stopResizeMonitoring,
}: StackedBarProps) => {
  const stackedBarChartData = useMemo(() => {
    if (!data || !isCompositionV2Data(data) || !secondaryDimension) return [];
    return getStackedBarData({
      entities: data.composition_v2?.entities ?? [],
      plotName: secondaryDimension,
      formatLabel:
        secondaryDimension === 'skills'
          ? (label) => label.split('/').shift() ?? label
          : undefined,
    });
  }, [data, secondaryDimension]);

  return (
    <StackedBarChartHorizontal
      data={stackedBarChartData}
      hideLegend={stopResizeMonitoring}
    />
  );
};
